import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../components/layout'
import Img from 'gatsby-image'
import { graphql, Link } from 'gatsby'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import things_logo from '../../assets/images/projects/service/tech/things-logo.jpg'
import tecne_logo from '../../assets/images/projects/service/tecne/tecne-logo.jpg'
import adobelogo from '../../assets/images/projects/service/mercedes/adobe_logo.png'
// import pic02 from '../../assets/images/projects/service/bsmart/19.png'
import pic04 from '../../assets/images/projects/19.png'




const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
}

const ServiceDesignTecne = props => (
  <Layout>
    <Helmet>
      <title>Service Design - COVID projects</title>
      <meta name="Service Design - Tecne" content="Service Design" />
    </Helmet>

    <div id="main" className="alt">
      <section id="work-banner">
        <div>
          <span className="image main">
            <Img fluid={props.data.tecnecover.childImageSharp.fluid} alt="" />
          </span>
        </div>
      </section>

      <div className="project-wrapper">
        <div className="project-content">
          <header>
            <p className="maisontags">Research, Service Design, Experience Design, Interaction Design, Digital UX/UI, Brand Identity</p>
            {/* <span className="tag">UX STRATEGY</span>
            <span className="tag">INTERACTION DESIGN</span>
            <span className="tag">DIGITAL UX</span>
            <span className="tag">DIGITAL UI</span>
            <span className="tag">PRODUCT DESIGN</span>
            <span className="tag">BRAND IDENTITY</span> */}
            <h1>AI-assisted health-tech services.</h1>

            <h3>
            To face the challenges posed by COVID-19, we designed an AI-assisted 
            service that allows users’ through their smartphone camera to get feedback
            regarding their vital signs, and if they are showing any symptoms, 
            with medical-grade accuracy.  
            </h3>
            <br />
            <h3>
            Through telemedicine, at-risk or positive users’ status are remotely
            monitored continuously with the same accuracy of an in-person consultation,
            reduce risking of exposure and providing the possibility of quick intervention 
            if required. 
            </h3>

          </header>


          <section className="header-post">
            <div className="headerpost">
              <div className="headerpost-left">
                <p>
                  *This project was carried out while working at the design
                  agency, THINGS, in Milan.
                </p>
                <p>
                  <a href="/say-hello"><u>Get in touch</u></a> with me for more 
                  details related to this project; such as my
                  role, the problems i faced, the processes i 
                  used and how i addressed the challenges.
                </p>
              </div>
              <div className="headerpost-right">
                <div className="client-image">
                  {/* <Img fluid={props.data.things_logo.childImageSharp.fluid} /> */}
                  <img className="span-4" src={things_logo} alt="things logo" />
                </div>
                <br />
                <p>
                  Things.is a design and innovation agency for the Internet of
                  Things. We create products and services with their own
                  experiential ecosystems outlined by multi-channel touch
                  points. We envision products and services in the IoT era
                  through a human-centred approach. www.things.is
                </p>
              </div>            

            </div>
          </section>

          <div className="process">           
          <div className="process-left">
                <h4>
                Context of the project
                </h4>
                <p>
                When the COVID-19 pandemic hit hard in Italy, we were tasked with designing 
                2 smart healthcare services within a month, ready to be launched as soon as possible.  
                </p>
                <p>
                Since the entire country was in lockdown, we had to work remotely
                whilst collaborating in an agile way to design an MVP version of 
                these services.   
                </p>
          </div>
          <div className="process-right">
                <h4>
                Project overview
                </h4>
                <span className="credits-text">
                  <strong>PROJECT TYPE:</strong> Smart healthcare service that makes use of
                   advanced technology to help users stay safe and informed about their health.
                </span>
                <br />
                <span className="credits-text">
                  <strong>TEAM MEMBERS:</strong> Design team of
                  THINGS, Milan
                </span>
                <br />
                <span className="credits-text">
                  <strong>MY ROLE:</strong> Desk research, preparing and conducting interviews, 
                  mapping service blueprint, wireframing of a mobile app, tablet app & web app 
                  dashboard, visual UI design of digital touchpoints.
                </span>
                <div className="challenge-client">
                <br />
                <h5>CLIENT</h5>
                  <div className="client-image">
                    <img className="span-3" src={tecne_logo} alt="bsmart logo" />
                  </div>
                </div>
            </div>
            </div>


          <section className="challenge-section">
            <span className="project-step-number">01</span>
            <h2>challenge</h2>
            <div className="challenge">
              <div className="challenge-desc">
                <p>
                COVID-19 had brought about a national emergency in Italy and other
                parts of the world. It was during this period when we designed 2 
                smart healthcare services for use in different scenarios.
                </p>
              </div>
            </div>

            <div className="image">
              <Img fluid={props.data.tecnechallenge.childImageSharp.fluid} />
            </div>

            <div className="process">           
            <div className="process-left">
            <h4>Service 1: docdot</h4>
            <p>
              The goal of this service was to empower citizens with an
               easy way to check their vital signs for any COVID-19 symptoms 
               from their homes, whilst allowing their doctors to remotely 
               monitor their health status, and intervene quickly if necessary.
            </p> 
            </div>
            <div className="process-right">
              
            <h4>Service 2: Back to Work</h4>
            <p>
            The enforced lockdown measures meant people couldn’t go to their
             workplaces. However, once the situation improves, they will start 
             returning to their workplaces. And, when that time comes, we want 
             to provide building owners, HR managers and receptionists a service 
             that will help them to manage the flow of their returning employees 
             in an organised and safe manner.
            </p>               
            </div>
            </div>
          </section>


          <section className="process-section">
            <span className="project-step-number">02</span>
            <h2>process</h2>

            <br />
            <br />
            <br />
            <br />

            <div className="process-image">
              <Img fluid={props.data.tecne_process.childImageSharp.fluid} />
            </div>

            <div className="process">           
            <div className="process-left">
            <h4>Research to understand the COVID context</h4>
              </div>
              <div className="process-right">
              <p>
              We began by first knowing more about COVID-19, its symptoms, and its effects on the body. 
              </p>               
              </div>
            </div>          
            
            <div className="image">
              <Img fluid={props.data.tecneprocess9.childImageSharp.fluid} />
            </div>

            <p className="full-textlarge">
            The best way to do this was to speak directly with medical experts 
            and clinicians (those not occupied with COVID-19 patients), as this
            allowed us to get accurate clinical information in the fastest way possible. 
            </p>

            <div className="image">
              <Img fluid={props.data.tecneprocess9.childImageSharp.fluid} />
            </div>


            <div className="process">           
              <div className="process-right">
              <p>
              We also spoke to people to understand their behaviours, thoughts and
               emotions. Tapping into our online user community, we researched the 
               pertinent needs and attitudes that were influenced by the pandemic.  
              </p>               
              </div>
            </div>


            <p className="full-textlarge">
            From the users, we wanted to know how their life was affected, 
            what were their behavioural changes, what was their level of 
            accessibility to  tools & information, & finally their current 
            emotional and mental state due to the pandemic.
            </p>

            <div className="process">           
              <div className="process-right">
              <p>
              Since we didn’t have much time in our hands to do extensive 
              user research, we also tapped on our previous research findings
               made while working on another healthcare project that involved 
               using of a smart health-tech product at home.  
              </p>               
              </div>
            </div>


            <div className="process">           
            <div className="process-left">
            <h4>Research analysis</h4>
              </div>
              <div className="process-right">
              <p>
              We used various collaborative tools to download all the 
              material captured from our research. Next, we organised a
               remote collaborative session with some key stakeholders to 
               analyse the research findings and uncover insights. 
              </p>               
              </div>
            </div>  

            <div className="image">
              <Img fluid={props.data.tecneprocess9.childImageSharp.fluid} />
            </div>

            <div className="process">           
            <div className="process-left">
            <h4>Key Medical & User Insight</h4>
              </div>
              <div className="process-right">
              <p>
              In March 2020, the World Health Organization (WHO) released 
              a document called “Clinical management of severe acute respiratory
               infection (SARI) when COVID-19 disease is suspected”. The document
                emphasizes the critical importance of monitoring oxygen saturation 
                (SpO2) in COVID-19 suspects, as an indication of a respiratory-related 
                issue. 
              </p>               
              </div>
            </div>  

            <div className="image">
              <Img fluid={props.data.tecneprocess9.childImageSharp.fluid} />
            </div>


            <div className="process">           
            <div className="process-left">
            <p className="half-textlarge">
            Measuring temperature is not enough.  
              </p>
              </div>
              <div className="process-right">
              <p>
              Many COVID-19 carriers do not develop the symptoms commonly
               associated with the disease, and for many of these carriers, 
               the disease can cause severe deterioration, exposing 
               respiration-related symptoms, such as fever, shortness 
               of breath, low oxygen saturation levels (SpO2), and 
               increased heart rate. 
              </p>               
              </div>
            </div>

            <div className="image">
              <Img fluid={props.data.tecneprocess9.childImageSharp.fluid} />
            </div>


            <div className="process">           
            
              <div className="process-right">
              <p>
              People while being quarantined at home, some of them 
              may feel symptoms similar to those of COVID-19. Not 
              knowing what is wrong with them, and not being allowed 
              to leave their homes for a check-up, they can turn to 
              online medical services.  
              </p>               
              </div>
            </div>


            <div className="bluebg">
            <p className="full-textlargebluebg">
            A physician can enquire on a call regarding the person’s 
            symptoms; such as body temperature, cough, sore throat,
             tiredness and more.  
            </p>
            <p className="full-textlargebluebg">
            And while all of these symptoms can be easily measured with 
            a home thermometer and by observation, when it comes to oxygen 
            saturation or respiration rate, almost no one is equipped with 
            proper tools to measure these from home.
            </p>
            </div>


            <div className="process">           
            <div className="process-left">
            <h4>The technology behind the scenes.</h4>  
              </div>
              <div className="process-right">
              <p>
              We used the SDK developed by a team of telemedicine 
              and data scientists led by the SDG Group & binah.ai. 
              </p> 
              <p>
              Using a unique combination of light signal processing 
              (remote photoplethysmography), machine learning and AI 
              is used to convert the light reflected from blood vessels 
              in users' face into highly accurate (Medical-grade) real-time 
              vital sign measurements. 
              </p>
              <p className="half-textlarge">
              All of this using only your phone’s camera.
              </p>
              <p>
              Their technology of vital signs monitoring solutions 
              have been developed and clinically tested together with 
              the vital signs monitoring systems used today in most hospitals. 
              </p>               
              </div>
            </div>



            

            <div className="image">
              <Img fluid={props.data.tecneprocess9.childImageSharp.fluid} />
            </div>


            <div className="process">           
            <div className="process-left">
            <h4>Designing the user experience for both services (1- docdot, 2 - Back to Work)</h4>  
              </div>
              <div className="process-right">
              <p>
              The insights generated from the design research enabled us to 
              design 2 holistic service experiences for 2 different scenarios.  
              </p>                
              </div>
            </div>


            <div className="process">           
            <div className="process-left">
            <p>
            Service 1 - docdot  
              </p>              
              </div>
            </div>


            <div className="process">           
            <div className="process-left">
            <p>
            Scenario: Patients use their phone to measure their vital signs, 
            which their doctor can track on their web app dashboard. 
              </p>
              <p>
              Touchpoints: Mobile app for the patients, and web app 
              dashboard for the doctors (clinical team) & hospital admin. 
              </p>              
              </div>
              <div className="process-right">
              <p>
              Main  phases of the experience:  
              </p>
              <ol>
                <li>
                Doctors inviting patients and creating a schedule for patients to carry out tests on their mobile app.
                </li>
                <br />

                <li>
                Patients measure their vital signs and their data getting stored in their EHR.
                </li>
                <br />

                <li>
                Doctors on their web app dashboard tracking overall data trends and checking the EHR of individual patients if there are any anomalies. 
                </li>
                <br />

                <li>
                Doctors making a video call to the patient.
                </li>
              </ol>                
              </div>
            </div>


            <div className="image">
              <Img fluid={props.data.tecneprocess9.childImageSharp.fluid} />
            </div>


            <div className="process">           
            <div className="process-left">
            <p>
            Service 2 - Back to work
              </p>              
              </div>
            </div>



            <div className="process">           
            <div className="process-left">
            <p>
            Scenario: Employees of a company use their phone to measure their 
            vital signs before leaving for work every day, and their result 
            checked by the receptionist at the entrance before being allowed 
            to enter the building.
              </p>
              <p>
              Touchpoints: Mobile app for the employees, web app dashboard 
              for the HR managers, building owners & receptionist & tablet 
              app at the entrance of the building. 
              </p>              
              </div>
              <div className="process-right">
              <p>
              Main  phases of the experience:  
              </p>
              <ol>
                <li>
                HR managers setting up the platform as per their office requirements and organisation rules/policies. 
                </li>
                <br />
                <li>
                HR managers invite their employees to use the app.
                Employees measure their vital signs everyday on the app before going to the office.
                </li>
                <br />

                <li>
                Receptionist checking the vital sign status of all employees and temporary visitors before allowing them to enter the building
                </li>
                <br />

                <li>
                HR managers having an overview of all the employees in the building and their status
                </li>
              </ol>                
              </div>
            </div>


            <div className="image">
              <Img fluid={props.data.tecneprocess9.childImageSharp.fluid} />
            </div>


            <div className="process">           
            <div className="process-left">
            <h4>Low fidelity wireframes of the digital touchpoints</h4>  
              </div>
              <div className="process-right">
              <p>
              From the service blueprint, we identified the points of interaction
              between the various stakeholders and touchpoints (physical and digital).  
              </p>                
              </div>
            </div>


            <p className="full-textlarge">
            For all the digital touchpoints, we understood their context 
            (spatial, physical & digital) of use and the need/goal of 
            the user at that moment.
            <br />
            <br />
            Next, we evaluated the different types of information
             and elements the users’ need to see or understand or act upon.
            <br />
            <br />
            And finally, the actions they can take on the interface.
            </p>

            

            {/* <p className="full-textlarge">
            For all the digital touchpoints, we understood their context 
            (spatial, physical & digital) of use and the need/goal of 
            the user at that moment.
            </p>

            <p className="full-textlarge">
            Next, we evaluated the different types of information
             and elements the users’ need to see or understand or act upon.
            </p>

            <p className="full-textlarge">
            And finally, the actions they can take on the interface.
            </p> */}


            <div className="process">           
            
              <div className="process-right">
              <p>
              For both the services, we started by making quick low fidelity 
              wireframes of important functionalities and interactions.  
              </p>
              <p>
              We then quickly made a user testing exercise with some 
              users online, before consolidating all the digital touchpoints.   
              </p>                 
              </div>
            </div>

            <div className="image">
              <Img fluid={props.data.tecneprocess9.childImageSharp.fluid} />
            </div>

            <div className="process">           
            <div className="process-left">
            <h4>Brand identity and visual design system</h4>  
              </div>
              <div className="process-right">
              <p>
              The two services have their own unique identity that is influenced by certain brand values.  
              </p>
              <p>
              The docdot service is designed to work for people of all ages, and thus 
              inclusive design principles were applied to its typography and colour palette.   
              </p>                
              </div>
            </div>

            <div className="image">
              <Img fluid={props.data.tecneprocess9.childImageSharp.fluid} />
            </div>

            <div className="process">           
            
              <div className="process-right">
              <p>
              On the other hand, for the Back to Work service, the inspiration for 
              the name and its brand values came from the call to action of getting
               back to something, after a break, an intermission or a pause (like
                the one we were living in during the pandemic).   
              </p>                 
              </div>
            </div>

            <div className="image">
              <Img fluid={props.data.tecneprocess9.childImageSharp.fluid} />
            </div>

          </section>



          <section className="outcome-section">
            <div className="outcome-text-wrapper">
              <span className="project-step-number">03</span>
              <h2>outcome</h2>
              
              <div className="outcome">
                <div className="outcome-right">
                <p>
                During the present COVID-19 pandemic crisis, the need 
                to protect citizens and medical personnel became even more vital.  
                </p>
                <p>
                Our digitally connected and AI-assisted healthcare solution has 
                enhanced and expanded the delivery of medical services by enabling
                self-and-remote diagnosis and triage of COVID-19 suspect people.  
                </p>
                </div>
              </div>

              <h3>
              In both the services we designed a health monitoring mobile app that 
              allows a video-based vital signs monitoring in real-time. It allows 
              anyone to easily measure oxygen saturation, respiration rate, heart 
              rate and HRV – just by looking into a smartphone camera. 
              <br />
              <br />

              These important measurements, greatly aid in deciding whether a person
               should seek further medical attention or, conversely, reduce risking 
               of exposure by remaining safely at home. 
               <br />
              <br />

              It goes a long way in lowering the overload of medical facilities and 
              burden on medical staff, and protecting health professionals.
              </h3>

              <div className="outcome">
                <div className="outcome-right">
                <p>
                Our app is built on the following characteristics: 
                </p>
                <ul>
                  <li>
                  Accessible - no wearables are required
                  </li>
                  <br />

                  <li>
                  Easy to use - vital signs are measured by simply looking at the smartphone camera
                  </li>
                  <br />

                  <li>
                  Accurate - the technology is clinically tested and has medical-grade accuracy.
                  </li>
                  <br />

                  <li>
                  Always available - app runs locally and thus doesn’t require an internet connection; 
                  </li>
                  <br />

                  <li>
                  Inclusive (spatial & physical) - uses motion compensation and illumination normalization and supports any age, sex and skin colour 
                  </li>
                </ul>
                
                </div>
              </div>

              
            </div>
          </section>
        </div>
      </div>


      <section className="fullspan">
        <div className="image">
          <Img fluid={props.data.tecneoutcome46.childImageSharp.fluid} />
        </div>
      </section>




      {/* remove these breaks below when the prototype code lines are un-commented */}

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />




                  {/* commented the below prototype link section till the prototype is made */}


      {/* <div className="project-wrapper">
      <div className="project-content">
      <section className="process-section">
          <div className="changebg">
          <br />
          <br />
          <div className="process">           
            <div className="process-left">
              <h4>
              Prototype of the mobile app
              </h4>
              </div>
              <div className="process-right">
              <div>
              <img className="protologo" src={adobelogo} alt="" />
              </div>
              
              <div className="resume-download">
              <a href={"https://medium.com/@sagarsatam"} target="_blank" rel="noopener noreferrer" className="resume-download-link" download>
              See Prototype
              </a>
              </div> 
                             
              </div>
            </div>  
          </div>
      </section>
      </div>
      </div> */}



      {/* <section className="fullspan">
        <div className="image">
          <Img fluid={props.data.tecneoutcome45.childImageSharp.fluid} />
        </div>
      </section> */}








      <div className="project-wrapper">
          <div className="project-content">
          {/* <section className="process"> */}
          <div className="changebg">

          
          <div className="process">           
            <div className="process-left">
              <h4>
              Problems faced during the project 
              and how they were solved
              </h4>
              </div>
              <div className="process-right">
              <p>
              Due to the pandemic situation, we were under the time crunch 
              to get the service out as soon as possible, and this meant 
              finding a way to do a fair amount of research and user testing, 
              which are design processes that can be time-intensive.   
              </p> 
              <p>
              Quick research was done by directly approaching the right people 
              who could provide us with the information we sought. We also used 
              our experience and insights from a previous healthcare project
               to enhance our research. On the other hand, rapid testing of 
               key screens and interaction was carried out, which allowed us
                to make small design iterations.   
              </p>               
              </div>
            </div>
            

            
            

            <br />
            <br />
            <div className="process">           
            <div className="process-left">
                  <h4>
                  Reflection - what I learnt during the project & what I could have done better.
                  </h4>
            </div>
              <div className="process-right">
              <p>
              During this pandemic, not only is it vital to ensure 
              the physical safety of people, emotional and mental 
              health is also something that needs consideration. 
              However, due to time and budget limitations, we were
               not able to design a more holistic service that has 
               a positive effect on their mental well-being.              
               </p> 
                              
              </div>
            </div>
          </div>
          <br />
          
          
          <p className="full-text">
          Use smart healthcare services & stay safe <span role="img" aria-labelledby="covidemoji">🤳🏻😷</span> 
          </p>
          <br />
          <br />
          <br />
          <br />
          <br />
          
          <div className="full-text">
          <div className="hp-featured__hr-line" />
          <h5>NEXT PROJECT</h5>
          </div>
          <br />


          {/* </section> */}
        </div>
      </div>


      <section id="two" className="tiles inner">
      {/* <div className="hp-featured__hr-line" />
        <h4>NEXT PROJECT</h4> */}
            <div className="tiles__wrapper">
              <a href="/work/service-design-muvisi-project03full2589">
                <div className="tiles__content">
                  <div className="tiles__info">
                    {/* <div className="tiles__number">01</div> */}

                    {/* <h2 className="tiles__title">
                      electric car charging service.
                    </h2> */}

                    <div className="tiles__text">
                    Next gen combat training with an underglove fitted with sensors 
                    & an app that gives precise feedback to improve.
                    </div>
                    <br />
                    <p className="maisontags-card">Research, UX Strategy, User Experience, Interaction Design, Digital UX/UI, Product Design, Brand Identity</p>
                    {/* <div className="tiles__tags">
                      <ul>
                        <li>SERVICE DESIGN</li>
                        <li>USER EXPERIENCE</li>
                        <li>DIGITAL UX/UI</li>
                        <li>INTERACTION DESIGN</li>
                      </ul>
                    </div> */}
                  </div>
                </div>
                <div className="tiles__image">
                  <img src={pic04} alt="" />
                </div>
              </a>
            </div>
      </section>

      










      {/* <section className="fullspan">
        <div className="image">
          <Img fluid={props.data.tecneoutcome45.childImageSharp.fluid} />
        </div>
      </section> */}
      {/* <section id="footertopcta" className="inner">
        <p className="text arrow-back">
          head back to check out my
          <br />
          <Link to="/#one">featured work.</Link>
        </p>
      </section> */}






    {/* <div className="footer-top">
      <div className="inner">
        <h2>other projects.</h2>
        <div id="work">
          <div className="card-wrapper">
            <a href="/work/service-design-mercedes">
              <div className="image">
                <Img fluid={props.data.project1.childImageSharp.fluid} alt="" />
              </div>
              <div className="card-info">
                <div className="title">electric car charging service.</div>
                <div className="text">
                  A seamless parking & charging solution for Mercedes Benz &
                  Smart Electric Car owners living in urban areas.
                </div>
                <div className="tags">
                  <ul>
                    <li>SERVICE DESIGN</li>
                    <li>USER EXPERIENCE</li>
                    <li>DIGITAL UX/UI</li>
                    <li>INTERACTION DESIGN</li>
                  </ul>
                </div>
              </div>
            </a>
          </div>
          <div className="card-wrapper">
            <a href="/work/service-design-hive-part-1">
              <div className="image">
                <Img fluid={props.data.project2.childImageSharp.fluid} alt="" />
              </div>
              <div className="card-info">
                <div className="title">hive (part 1).</div>
                <div className="text">
                  Unique experiences that bring people together over home cooked
                  meals hosted by local chefs in neighbourhoods.
                </div>
                <div className="tags">
                  <ul>
                    <li>SERVICE DESIGN</li>
                    <li>USER EXPERIENCE</li>
                    <li>UX STRATEGY</li>
                    <li>BUSINESS DESIGN</li>
                  </ul>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div> */}


  </div>
  </Layout>
)

export default ServiceDesignTecne

export const fluidTecneImage = graphql`
  fragment fluidTecneImage on File {
    childImageSharp {
      fluid(maxWidth: 920, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    tecnecover: file(
      relativePath: { eq: "projects/service/tecne/cover/tecne-cover-image.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecne_logo: file(
      relativePath: { eq: "projects/service/tecne/tecne-logo.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecnechallenge: file(
      relativePath: { eq: "projects/service/tecne/challenge/1.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess2: file(
      relativePath: { eq: "projects/service/tecne/process/2.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess3: file(
      relativePath: { eq: "projects/service/tecne/process/3.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess4: file(
      relativePath: { eq: "projects/service/tecne/process/4.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess5: file(
      relativePath: { eq: "projects/service/tecne/process/5.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess6: file(
      relativePath: { eq: "projects/service/tecne/process/6.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess7: file(
      relativePath: { eq: "projects/service/tecne/process/7.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess8: file(
      relativePath: { eq: "projects/service/tecne/process/8.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess9: file(
      relativePath: { eq: "projects/service/tecne/process/9.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess10: file(
      relativePath: { eq: "projects/service/tecne/process/10.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess11: file(
      relativePath: { eq: "projects/service/tecne/process/11.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess12: file(
      relativePath: { eq: "projects/service/tecne/process/12.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess13: file(
      relativePath: { eq: "projects/service/tecne/process/13.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess14: file(
      relativePath: { eq: "projects/service/tecne/process/14.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess15: file(
      relativePath: { eq: "projects/service/tecne/process/15.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess16: file(
      relativePath: { eq: "projects/service/tecne/process/16.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess17: file(
      relativePath: { eq: "projects/service/tecne/process/17.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess18: file(
      relativePath: { eq: "projects/service/tecne/process/18.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess19: file(
      relativePath: { eq: "projects/service/tecne/process/19.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess20: file(
      relativePath: { eq: "projects/service/tecne/process/20.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess21: file(
      relativePath: { eq: "projects/service/tecne/process/21.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess22: file(
      relativePath: { eq: "projects/service/tecne/process/22.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess23: file(
      relativePath: { eq: "projects/service/tecne/process/23.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess24: file(
      relativePath: { eq: "projects/service/tecne/process/24.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess25: file(
      relativePath: { eq: "projects/service/tecne/process/25.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess26: file(
      relativePath: { eq: "projects/service/tecne/process/26.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess27: file(
      relativePath: { eq: "projects/service/tecne/process/27.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess28: file(
      relativePath: { eq: "projects/service/tecne/process/28.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess29: file(
      relativePath: { eq: "projects/service/tecne/process/29.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess30: file(
      relativePath: { eq: "projects/service/tecne/process/30.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess31: file(
      relativePath: { eq: "projects/service/tecne/process/31.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess32: file(
      relativePath: { eq: "projects/service/tecne/process/32.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess33: file(
      relativePath: { eq: "projects/service/tecne/process/33.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess34: file(
      relativePath: { eq: "projects/service/tecne/process/34.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess35: file(
      relativePath: { eq: "projects/service/tecne/process/35.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess36: file(
      relativePath: { eq: "projects/service/tecne/process/36.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess37: file(
      relativePath: { eq: "projects/service/tecne/process/37.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess38: file(
      relativePath: { eq: "projects/service/tecne/process/38.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess39: file(
      relativePath: { eq: "projects/service/tecne/process/39.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess40: file(
      relativePath: { eq: "projects/service/tecne/process/40.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess41: file(
      relativePath: { eq: "projects/service/tecne/process/41.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess42: file(
      relativePath: { eq: "projects/service/tecne/process/42.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess43: file(
      relativePath: { eq: "projects/service/tecne/process/43.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess44: file(
      relativePath: { eq: "projects/service/tecne/process/44.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneoutcome46: file(
      relativePath: { eq: "projects/service/tecne/outcome/46.jpg" }
    ) {
      ...fluidTecneImage
    }
    things_logo: file(
      relativePath: { eq: "articles/mobileiot/things-logo.png" }
    ) {
      ...fluidTecneImage
    }
    tecne_process: file(
      relativePath: { eq: "projects/process/tecne-process.png" }
    ) {
      ...fluidTecneImage
    }
    project1: file(relativePath: { eq: "projects/3.png" }) {
      ...fluidTecneImage
    }
    project2: file(relativePath: { eq: "projects/10.png" }) {
      ...fluidTecneImage
    }
  }
`
